import React, { useEffect } from "react";
import { COUPON_IDS, LABOR_DAY_COUPON } from "../../../util/variables";
import { discount_flow_appear } from "../../../services/mixpanel/discount-flow-events";
import { DiscountSSOPage } from "../../../components/PageSegments/subscription/DiscountSSOPage";
import { BasicLayout } from "../../../layouts/BasicLayout";
import { US_PRICE_IDS } from "../../../util/local-price-ids";

// This page is used for the $149.99 email flow with 30% off
export default (data: any) => {
  const urlParams = new URLSearchParams(data.location.search);
  const promoParam = urlParams.get("promo") ?? undefined;
  const coupon = promoParam === "labor_day" ? LABOR_DAY_COUPON : COUPON_IDS.percent40;

  useEffect(() => {
    if (promoParam === "labor_day") {
      localStorage.setItem("promo", promoParam);
    }

    localStorage.setItem("flow", `discount - 149.99 - 30% off`);
    discount_flow_appear();
  }, [promoParam]);

  return (
    <BasicLayout noIndex>
      <DiscountSSOPage priceId={US_PRICE_IDS.no_trial[149.99]} couponId={coupon} />
    </BasicLayout>
  );
};
